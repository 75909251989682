<template>
    <div>
        <div class="navbar swing-in-top-fwd">
            <!-- 左侧logo -->
            <div class="nav-l">

                <div style="cursor: pointer;" @click="goHome()">
                    <img class="logo" src="../assets/image/logo.png" alt="">
                </div>


                <nav class="nav-m" style="margin-left: 60px;">

                    <div class="m-item">
                        <router-link class="router-link" to="/" exact active-class="active-link">首页</router-link>
                    </div>

                    <div class="m-item">
                        <img src="../assets/image/shopCar.png" class="m-item-img" alt="">
                        <router-link class="router-link" to="/purchase" active-class="active-link">套餐购买</router-link>
                    </div>



                    <div class="m-item" v-if="token">
                        <router-link class="router-link" to="/extract" exact
                            active-class="active-link">提取长效</router-link>
                    </div>

                    <div class="m-item">
                        <router-link class="router-link" to="/service" active-class="active-link">专业技术服务</router-link>
                    </div>

                   
                </nav>

            </div>
            <!-- 右侧登录注册 -->
            <div class="nav-r">
                <nav class="nav-m">
                    <!-- <div class="m-item">
                        <router-link class="router-link" to="/" exact active-class="active-link">首页</router-link>
                    </div>
                    <div class="m-item">
                        <img src="../assets/image/shopCar.png" class="m-item-img" alt="">
                        <router-link class="router-link" to="/purchase" active-class="active-link">套餐购买</router-link>
                    </div>
                    <div class="m-item" v-if="token">
                        <router-link class="router-link" to="/extract" exact active-class="active-link">提取长效</router-link>
                    </div> -->

                    <div class="m-item">
                        <router-link class="router-link" to="/tutorials" active-class="active-link">使用教程</router-link>
                    </div>

                    <!-- 已登录-->
                    <div v-if="token" style="display: flex;align-items: center;">

                        <!-- <div class="m-item">
                            <div :class="purchaseIndex === 5 ? 'router-link ':'router-link'"
                                @click="goPurchase(5)">推广中心</div>
                        </div> -->
                        
                        
                        <div class="m-item" v-if="token">
                            <router-link class="router-link" to="/feedback" exact
                                active-class="active-link">反馈系统</router-link>

                            <div class="message-label" v-if="my_handle > 0">{{ my_handle }}</div>
                        </div>

                        <div class="m-item" v-if="token">
                            <router-link class="router-link" to="/promotion" exact
                                active-class="active-link">推广中心</router-link>
                        </div>

                        <div style="display: flex;align-items: center;">
                            <img src="../assets/image/icon_head.png" alt="">
                            <router-link class="router-link" to="/personal" active-class="active-link"
                                style="margin-left:6px;"> {{ nickname }}
                            </router-link>
                        </div>

                        <div class="r-exit" @click="exitLogin()">
                            退出登录
                        </div>
                    </div>

                    <!-- 未登录 -->
                    <div v-else style="display: flex;align-items: center; ">
                        <div class="r-login" @click="login()" :id="loginBox2 ? 'isClick' : ''">
                            <img src="../assets/image/icon_login.png" alt="">
                            <span style="margin-left: 4px;">登录</span>
                        </div>
                        <!-- <router-link class="router-link" to="/personal" active-class="active-link">个人中心</router-link> -->
                        <div class="r-exit" @click="register()" :id="loginBox1 ? 'isClick' : ''">
                            <img src="../assets/image/icon_register.png" alt="">
                            <span style="margin-left: 4px;">注册</span>
                        </div>
                    </div>
                </nav>

            </div>
        </div>





        <!--登录/注册 -->
        <Login ref="login"></Login>






    </div>
</template>
<script>
import { workOrderStat } from '@/api/feedback.js'
// import { Message } from 'element-ui';

//解构引入
// 引入封装的左右摆动动画组件
import ShakeInput from '@/components/ShakeInput.vue'

//引入登录注册等
import { userLogin, userSmsSend, userRegister, userForgetPwd } from '@/api/user.js'
import { userInfo } from '@/api/personal.js'

//MD5加密
import CryptoJS from 'crypto-js';
import Login from '@/components/Login.vue'

import Captcha from '@/components/Captcha.vue'
import Vcode from "vue-puzzle-vcode";
import codeImg from "../assets/static/codeImg";

export default {
    name: 'Navbar',
    components: {
        Captcha,
        Vcode,
        ShakeInput,
        Login,

    },
    data() {
        return {
            //登录/注册样式
            // highlight: false,
            nickname: '',
            userPhone: '',
            token: '',
            // 定义一个验证码的值用于判断是否为空为空就触发动画
            smsCode: '',
            // 拼图验证是否通过
            isAllow: false,
            isShow: false,
            imgsrc: codeImg,
            // 登录
            loginBox1: false,
            // 注册
            loginBox2: false,
            passwordShow1: 'password',
            eyeImage1: false,
            passwordShow2: 'password',
            eyeImage2: false,
            // 自动登录选项
            checked: true,
            // 是否同意协议
            checkedAgree: false,
            // 切换账号登录和密码登录的index
            tab1Index: 1,
            // 切换注册和忘记密码的index
            tab2Index: 1,
            // 验证码倒计时
            counter: 0,
            //是否旋转
            isRotate: false,
            // 滑块组件
            isPassing: false,
            phone: '',
            password: '',
            newPassword: '',
            shakePhone: false,
            shakePassword: false,
            shakeNewPassword: false,
            errorMessage: '',
            errorField: null, // 新增一个属性用于标记出错的字段,
            token: '',
            //跳转个人中心默认打开组件索引
            purchaseIndex: null,
            my_handle: 0
        }
    },
    //监听
    watch: {

    },
    async created() {
        this.token = localStorage.getItem('token')

        // this.userPhone = localStorage.getItem('userPhone')
        if (this.token) {
            let res = await userInfo()
            // //////////console.logres.data.data.nickname,'信息查询》》？？');
            this.nickname = res.data.data.nickname


            let res2 = await workOrderStat()
            this.my_handle = res2.data.data.my_handle

            setInterval(() => {
                this.my_handle = localStorage.getItem('my_handle') ? localStorage.getItem('my_handle') : this.my_handle
            }, 1000);
        }



    },
    mounted() {

    },
    methods: {
        //电击跳转推广中心/用户反馈
        goPurchase(index) {
            this.purchaseIndex = index
            if (index === 5) {
                localStorage.setItem('menuIndex', 5)
                this.$router.push({
                    path: '/personal',
                    // query: {
                    //     menuIndex: 5
                    // }
                });
                this.personal.$refs.changeMenuIndex(5)
                // this.$router.go(0);
                // window.location.reload()
            }

            if (index === 6) {
                localStorage.setItem('menuIndex', 6)
                this.$router.push({
                    path: '/personal',
                    // query: {
                    //     menuIndex: 5
                    // }
                });
                this.personal.$refs.changeMenuIndex(6)
                // this.$router.go(0);
                // window.location.reload()
            }


        },

        goHome() {
            this.$router.push('/')
        },

        //点击导航栏登录按钮
        login() {
            this.$refs.login.login()


        },
        register() {
            // if (this.loginBox2 == false) {
            //     this.loginBox2 = true
            // }
            this.$refs.login.register()
            // this.highlight = true
        },
        changeEyes1() {
            this.eyeImage1 = !this.eyeImage1
            this.passwordShow1 == 'password' ? this.passwordShow1 = 'text' : this.passwordShow1 = 'password';

        },

        changeEyes2() {
            this.eyeImage2 = !this.eyeImage2
            this.passwordShow2 == 'password' ? this.passwordShow2 = 'text' : this.passwordShow2 = 'password';

        },
        goPwd() {
            this.tab1Index = 1
        },
        goCode() {
            this.tab1Index = 2
        },



        //点击退出登录
        exitLogin() {
            // 退出登录清空token
            // //////////console.log'点击退出登录');

            this.$confirm('确定退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'btnConfirm',
                cancelButtonClass: 'btnCancel',
                type: 'warning'
            }).then(() => {
                localStorage.clear()
                // this.$router.replace({path:'/'})
                // 假设你正要导航到根路径 '/'
                if (this.$router.currentRoute.path !== '/') {
                    this.$router.push('/').catch(err => {
                        // 导航守卫里可能会发生未捕获的错误
                        // 但是并不总是真正的问题，可以忽略 NavigationDuplicated 错误
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
                window.location.reload()
                // this.$message({
                //     type: 'success',
                //     message: '成功退出!'
                // });

                // window.location.reload()
                // this.$router.push('/purchase')
            }).catch(() => {
                // this.$message({
                //     type: 'warning',
                //     message: '已取消退出!'
                // });
            });
        },











    }
}
</script>
<style lang="less" scoped>
.btnConfirm {
    background: #4C6FF0 !important;
    // background: #F32D3E !important;
    border-radius: 4px 4px 4px 4px !important;
    border: 1px solid #4C6FF0 !important;
}






// 左右摆动动画
@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
}







//旋转动作
#isRotate {
    animation: rotateY 1s forwards;
}

//可不可以点击
#isClick {
    pointer-events: none;
}

#unclickable {
    opacity: 0.5;
}


//单选框的颜色
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4C6FF0;
    border-color: #4C6FF0;
}


.router-link {
    text-decoration: none !important;
    margin-right: 48px;
    font-size: 14px;
    position: relative;
    color: #FFFFFF;
    cursor: pointer;
}

.router-link::before,
.router-link::after {
    content: "";
    position: absolute;
    bottom: -20px;
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transition: width 0.3s ease-in-out;
}

.router-link::before {
    left: 50%;
    transform: translateX(-50%);
}

.router-link::after {
    right: 50%;
    transform: translateX(50%);
}

.router-link.active-link::before,
.router-link.active-link::after,
.router-link:hover::before,
.router-link:hover::after {
    width: 100%;
    transition: width 0.3s ease-in-out;
}

@keyframes backgroundShift {
    0% {
        background-position: right bottom;
    }

    100% {
        background-position: left bottom;
    }
}


@keyframes fadeIn {
    from {
        color: #1A1E39;
        opacity: 0;
    }

    to {
        color: #4C6FF0;
        opacity: 1;
    }
}

// 旋转动画 
@keyframes rotateY {
    from {
        // 开始时 为旋转
        transform: rotateY(180deg);
    }

    to {
        transform: rotateY(0deg);
    }
}



// 选中的tab样式、
#activeTab {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #4C6FF0;
    line-height: 28px;
    position: relative;
    animation: fadeIn 0.5s;

}

#activeTab::after {
    content: '';
    height: 4px;
    width: 25px;
    background: #4C6FF0;
    position: absolute;
    left: 26px;
    bottom: -10px;
    animation: fadeIn 0.5s;
}














.navbar {
    width: 100%;
    height: 72px;
    background: #081D39;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
}

.nav-l {
    display: flex;
    align-items: center;
    margin-left: 32px;

    .logo {
        width: 120px;
        height: 36px;
    }

    .slgn {
        width: 68px;
        height: 20px;
        margin-left: 18px;
    }

}

.nav-m {
    //  background: salmon;
    height: 70px;
    display: flex;
    align-items: center;


}

.nav-r {
    display: flex;
    align-items: center;


    .r-login {
        width: 92px;
        height: 32px;
        border-radius: 6px;
        text-align: center;
        font-size: 14px;
        line-height: 32px;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid #ffffff;
        // /* 这里指定了背景颜色和渐变方向的过度 */
        // transition: all 0.3s ease-in-out;
        // border-image:linear-gradient(45deg,gold,deeppink) 1;
        // clip-path: inset(0 round 10px);
        //  border: 1px solid;
        // border-image: linear-gradient(90deg, rgba(76, 111, 240, 1), rgba(28, 246, 234, 1)) 1 1;

        // clip-path: inset(0 round 12rpx);
        transition: all 0.5s ease-in-out;
        background-position: left bottom;
        background-size: 200% 200%;
    }

    .r-login:hover {
        border: none;
        background: linear-gradient(90deg, #4C6FF0 0%, #1CF6EA 100%);
        // background-position: right bottom;
    }


    .r-exit {
        width: 92px;
        height: 32px;
        border-radius: 6px 6px 6px 6px;
        /* // border: 1px solid #FFFFFF; */
        text-align: center;
        font-size: 14px;
        line-height: 32px;
        color: #FFFFFF;
        margin-left: 30px;
        margin-right: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        /* // background: linear-gradient( 90deg, #4C6FF0 0%, #1CF6EA 100%); */
        transition: all 0.5s ease-in-out;
        background-position: left bottom;
        background-size: 200% 200%;
    }

    .r-exit:hover {
        // background-position: right bottom;
        border: none;
        background: linear-gradient(90deg, #4C6FF0 0%, #1CF6EA 100%);
    }

}

.m-item {
    display: flex;
    align-items: center;
    position: relative;

    .m-item-img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}


.content-inp-item1-img1 {
    width: 20px;
    height: 20px;
    background: url(../assets/image/icon-user.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 6px;
    // background-position: 0 0 ;
}

.content-inp-item1-img2 {
    width: 20px;
    height: 20px;
    background: url(../assets/image/icon-secure.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 6px;
    // background-position: 0 0 ;
}

.content-inp-item1-img3 {
    width: 20px;
    height: 20px;
    background: url(../assets/image/icon-lock.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 6px;
    // background-position: 0 0 ;
}

.content-inp2-btn {
    width: 100px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #4C6FF0;
    margin-left: 10px;


    .inp2-btn-p {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #1A1E39;
        line-height: 44px;
        cursor: pointer;
    }
}


.content-inp-item1 {
    cursor: pointer;
    color: #6b6b6b;
    font-size: 15px;
}

.content-inp-item2 {
    cursor: pointer;
    color: #0dbdc4;
    font-size: 15px;
    position: relative;
}


.vue-auth-box_ {
    position: absolute;
    top: 40%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    background: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.error-alert {
    height: 34px;
    line-height: 34px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 50%;
    top: 30%;
    z-index: 99;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;

    padding: 8px;
    transition: all 0.3s ease-in-out;
}

.error-alert img {
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.error-alert span {
    font-size: 12px;
    color: #fff;

    line-height: 20px;
}

//过度开始状态
.fade2-enter-active,
.fade2-leave-active {
    transition: opacity 0.5s;
}

// 过度结束状态
.fade2-enter,
.fade2-leave-to

/* .fade-leave-active 在Vue 2.1.8+ */
    {
    opacity: 0;
}

.message-label {
    background: linear-gradient(90deg, #4C6FF0 0%, #1CF6EA 100%);
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 20px;
    color: #fff;
    position: absolute;
    right: 22px;
    top: -22px;
}

.message-label::after {
    content: "";
    position: absolute;
    left: -18%;
    bottom: -4px;
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-color: #428ce1 transparent transparent transparent;
    transform: rotate(52deg);
}

.scale-in-ver-top {
    -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-24 17:43:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-10-24 17:44:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

</style>